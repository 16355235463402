<template>
    <div class="page">
        <TitleHeader :title="title" />

        <div class="px-3">
            <div class="btn bg-sub text-primary w-100">
                Lorem ipsum
            </div>
            <CateBtns :list="cate" />
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
import CateBtns from '@/components/common/CateBtns.vue'

export default {
    components:{
        TitleHeader,
        CateBtns
    },
    data(){
        return{
            title: '제휴점 BANK',
            cate: [
                {
                    title: '제휴점<br>가입',
                    link: '/'
                },
                {
                    title: '가입<br>절차',
                    link: '/'
                },
                {
                    title: '준비<br>서류',
                    link: '/'
                },
                {
                    title: '쿠폰<br>신청',
                    link: '/'
                },
                {
                    title: '본사<br>지원',
                    link: '/'
                },
                {
                    title: '유저<br>관리',
                    link: '/'
                },
            ]
        }
    }

}
</script>
