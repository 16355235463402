<template>
    <div class="page">
        <TitleHeader :title="title" />

        <div class="px-3">
            <div class="btn bg-sub text-primary w-100">
                Lorem ipsum
            </div>
            <CateBtns :list="cate" />
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
import CateBtns from '@/components/common/CateBtns.vue'

export default {
    components:{
        TitleHeader,
        CateBtns
    },
    data(){
        return{
            title: 'My 계정',
            cate: [
                {
                    title: '회원<br>가입',
                    link: '/'
                },
                {
                    title: '회원<br>등급',
                    link: '/'
                },
                {
                    title: '정보<br>수정',
                    link: '/'
                },
            ]
        }
    }

}
</script>
