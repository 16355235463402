<template>
    <div class="last-border-0">
        <div v-for="(item,index) in list" :key="index" class="border-bottom py-4">
            <div class="flex-between-center">
                <h5 class="fw-bold mb-2" v-html="item.title"></h5>
                <img src="https://bccard.com/images/individual/card/renew/list/card_323761.png" alt="" width="50">
            </div>
            <p class="text-blue fw-bold flex-start-start mb-2"><i class="d-inline-block w-px-8 h-px-8 bg-warning rounded-circle mt-2 me-2"></i> {{item.reward}}</p>
            <p v-html="item.memo" class="fs-px-14 lh-sm"></p>
        </div>
    </div>
    
</template>

<script>
export default {
    data(){
        return{
            list: [
                {
                    title: 'Lorem ipsum<br>dolor sit',
                    reward: '이용카드 현금 17.5만원 증정',
                    memo: `· Lorem ipsum dolor sit amet.<br>
                        · Eos soluta odio repudiandae persp.`,
                },
                {
                    title: 'Lorem ipsum<br>dolor sit',
                    reward: '이용카드 현금 17.5만원 증정',
                    memo: `· Lorem ipsum dolor sit amet.<br>
                        · Eos soluta odio repudiandae persp.`,
                },
                {
                    title: 'Lorem ipsum<br>dolor sit',
                    reward: '이용카드 현금 17.5만원 증정',
                    memo: `· Lorem ipsum dolor sit amet.<br>
                        · Eos soluta odio repudiandae persp.`,
                },
            ]
        }
    }
}
</script>

<style>

</style>