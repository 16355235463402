<template>
    <div class="page">
        <TitleHeader :title="title" />

        <div class="px-3">
            <div class="btn bg-sub text-primary w-100 flex-between-center">
                <span>알림 수신량</span>
                <span>총 15건</span>
            </div>
            <CateBtns :list="cate" />

            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
import CateBtns from '@/components/common/CateBtns.vue'

export default {
    components:{
        TitleHeader,
        CateBtns
    },
    data(){
        return{
            title: '알림 BANK',
            cate: [
                {
                    title: '회원<br>유저',
                    link: '/notice-bank/user'
                },
                {
                    title: '제휴점',
                    link: '/notice-bank/smc'
                },
                {
                    title: '대리점<br>사업자',
                    link: '/notice-bank/agency'
                },
            ],
        }
    },
    
    computed:{
    },
    methods:{
    },

}
</script>
