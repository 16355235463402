<template>
    <div class="page">
        <TitleHeader :title="title" />
        <div class="py-3 px-4 mx-3 rounded-4 mb-3 bg-gray-100">
            <h5 class="text-primary mb-2">Mission bank</h5>
            <p>유저들의 노래 가창수익 외 별도의 리워드를 제공해주는 콘텐츠와 각종 정보 안내를 해주는 코너입니다. </p>
        </div>
        <div class="py-3 px-4 mx-3 rounded-4 mb-3 bg-sub">
            <h5 class="text-primary mb-2 text-center">오늘 새로 개설된 미션</h5>
            <p class="text-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
        </div>

        <div class="px-3">
            <div class="flex-between-center gap-3">
                <router-link to="/ad-mission" class="bg-gray-200 rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div>광고<br>미션</div>
                    </div>
                </router-link>
                <router-link to="/servey-mission" class="bg-gray-200 rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div>설문<br>미션</div>
                    </div>
                </router-link>
                <router-link to="/" class="bg-gray-200 rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div>앱설치<br>미션</div>
                    </div>
                </router-link>
                <router-link to="/" class="bg-gray-200 rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div>카드<br>미션</div>
                    </div>
                </router-link>
            </div>
            <hr>
            
            <div class="flex-between-center gap-3 mb-3">
                <router-link to="/notice-bank" class="bg-gray-200 rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div>알림<br>BANK</div>
                    </div>
                </router-link>
                <router-link to="/business-bank" class="bg-gray-200 rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div>창업<br>BANK</div>
                    </div>
                </router-link>
                <router-link to="/partner-bank" class="bg-gray-200 rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div>제휴점<br>BANK</div>
                    </div>
                </router-link>
                <router-link to="/qna-bank" class="bg-gray-200 rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div>Q&A<br>BANK</div>
                    </div>
                </router-link>
            </div>

            <div class="flex-between-center gap-3">
                <router-link to="/shopping-bank" class="bg-gray-200 rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div>쇼핑<br>BANK</div>
                    </div>
                </router-link>
                <router-link to="/tour-bank" class="bg-gray-200 rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div>TOUR<br>BANK</div>
                    </div>
                </router-link>
                <router-link to="/game-bank" class="bg-gray-200 rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div>게임<br>BANK</div>
                    </div>
                </router-link>
                <router-link to="/gift-bank" class="bg-gray-200 rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div>경품<br>BANK</div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        TitleHeader,
    },
    data(){
        return{
            title: 'mission BANK'
        }
    }

}
</script>
