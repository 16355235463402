<template>
    <div class="flex-evenly-center gap-3 py-3 flex-wrap" v-if="list != null">
        <router-link :to="item.link" class="bg-gray-200 rounded-2 w-25 ratio ratio-1x1 position-relative" v-for="(item,index) in list" :key="index">
            <div class="inner flex-center-center text-center">
                <div v-html="item.title"></div>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    props:{
        list:{
            type:Array,
            default: null,
        }
    },
    data(){
        return{

        }
    }
}
</script>

<style>

</style>