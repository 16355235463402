<template>
    <div class="mt-3">
        <ul class="basic_list d-flex flex-wrap flex-column" v-if="list&&list.length>0">
            <li v-for="(item,index) in list" :key="index" class="d-flex justify-content-between mb-3 border-bottom pb-3">
                <router-link :to="`/cs/event/${item.idx}`" class="left d-flex w-100">
                    <img :src="item.thumbnail" class="border ratio ratio-1x1 me-3" alt="event thumbnail" style="width:60px;">
                    <div class="d-flex flex-column justify-content-center">
                        <span class="text-truncate">{{ item.title }}</span>
                        <small class="text-body text-opacity-50 text-truncate"><span class="small">{{ item.start_date }}</span> ~ <span class="small">{{ item.end_date }}</span></small>
                    </div>
                </router-link>
            </li>
        </ul>
        
        <div class="empty_data" v-else>
            <p class="text-center py-5">
                게시글이 없어요.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            list: [
                {
                    idx: 0,
                    thumbnail: "https://via.placeholder.com/100x100",
                    title: "이벤트의 제목입니다.",
                    start_date: "2022-12-27",
                    end_date: "2023-01-27",
                    end: false,
                },
                {
                    idx: 1,
                    thumbnail: "https://via.placeholder.com/100x100",
                    title: "이벤트의 제목입니다.",
                    start_date: "2022-12-27",
                    end_date: "2023-01-27",
                    end: false,
                },
                {
                    idx: 2,
                    thumbnail: "https://via.placeholder.com/100x100",
                    title: "이벤트의 제목입니다.",
                    start_date: "2022-12-27",
                    end_date: "2023-01-27",
                    end: false,
                },
                {
                    idx: 3,
                    thumbnail: "https://via.placeholder.com/100x100",
                    title: "이벤트의 제목입니다.",
                    start_date: "2022-12-27",
                    end_date: "2023-01-27",
                    end: true,
                },
                {
                    idx: 4,
                    thumbnail: "https://via.placeholder.com/100x100",
                    title: "이벤트의 제목입니다.",
                    start_date: "2022-12-27",
                    end_date: "2023-01-27",
                    end: true,
                },
                {
                    idx: 5,
                    thumbnail: "https://via.placeholder.com/100x100",
                    title: "이벤트의 제목입니다.",
                    start_date: "2022-12-27",
                    end_date: "2023-01-27",
                    end: true,
                },
            ],
        }
    }
}
</script>

<style>

</style>