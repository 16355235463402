<template>
    <div class="page">
        <TitleHeader :title="title" />

        <div class="px-3">
            <div class="btn bg-sub text-primary w-100 flex-between-center">
                <span>미션 수신량</span>
                <span>총 15건</span>
            </div>
            <CateBtns :list="cate" />

            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
import CateBtns from '@/components/common/CateBtns.vue'

export default {
    components:{
        TitleHeader,
        CateBtns
    },
    data(){
        return{
            title: 'Misson BANK',
            cate: [
                {
                    title: '설문<br>조사',
                    link: '/servey-mission/servey'
                },
                {
                    title: '제휴<br>카드',
                    link: '/servey-mission/affiliate-card'
                },
                {
                    title: '이벤트<br>참가',
                    link: '/servey-mission/event'
                },
            ]
        }
    }

}
</script>
