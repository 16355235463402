<template>
    <div class="page">
        <TitleHeader :title="title" />

        <div class="px-3">
            <div class="btn bg-sub text-primary w-100 flex-between-center mb-2">
                <span>광고 수신량</span>
                <span>총 195건</span>
            </div>
            <div class="btn bg-green text-green w-100 flex-between-center mb-2">
                <span>광고 시청량</span>
                <span>총 153건</span>
            </div>
            <div class="btn bg-gray-100 text-gray w-100 flex-between-center">
                <span>미시청광고수량</span>
                <span>총 45</span>
            </div>
            <ul class="py-3">
                <li v-for="(item,index) in list" :key="index" class="mb-2 shadow p-3">
                    <div class="d-flex flex-column">
                        <div>{{ item.title}}</div>
                        <div class="d-flex justify-content-between align-items-center w-100">
                            <div class="text-gray-500 fs-px-14">
                                시청제한 : {{item.limit}}명<br>
                                경품수량 : {{ item.gift }}<br>
                                잔여경품 : {{item.remain}}
                            </div>
                            <div class="d-flex flex-column">
                                <button class="btn btn-sm btn-primary mb-2" @click="$router.push('/ad-mission/0')">광고보기</button>
                                <button class="btn btn-sm btn-secondary">{{item.name}}</button>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        TitleHeader,
    },
    data(){
        return{
            title: 'Ads BANK',
            list: [
                {
                    type:'video',
                    title: 'lorem lipsum',
                    limit:500000,
                    gift:'',
                    remain:'',
                    name:'홍길동',
                },
                {
                    type:'video',
                    title: 'lorem lipsum',
                    limit:500000,
                    gift:'',
                    remain:'',
                    name:'홍길동',
                },
                {
                    type:'video',
                    title: 'lorem lipsum',
                    limit:500000,
                    gift:'',
                    remain:'',
                    name:'홍길동',
                },
                {
                    type:'video',
                    title: 'lorem lipsum',
                    limit:500000,
                    gift:'',
                    remain:'',
                    name:'홍길동',
                },
                {
                    type:'video',
                    title: 'lorem lipsum',
                    limit:500000,
                    gift:'',
                    remain:'',
                    name:'홍길동',
                },
                {
                    type:'video',
                    title: 'lorem lipsum',
                    limit:500000,
                    gift:'',
                    remain:'',
                    name:'홍길동',
                },
                
            ]
        }
    }

}
</script>
