<template>
  
    <div class="page">
        <TitleHeader :title="title" />
        <div class="content container py-3 d-flex flex-column justify-content-between">
            <div class="bg-white rounded p-3">
                <div class="mb-3">Q. 퀴즈</div>
                <div class="btn bg-sub text-primary w-100 flex-between-center mb-2">
                    1. 답변
                </div>
                <div class="btn bg-green text-green w-100 flex-between-center mb-2">
                    2. 답변
                </div>
            </div>
            <div class="bg-white rounded p-3">
                <div class="mb-3">Q. 퀴즈</div>
                <div class="btn bg-sub text-primary w-100 flex-between-center mb-2">
                    1. 답변
                </div>
                <div class="btn bg-green text-green w-100 flex-between-center mb-2">
                    2. 답변
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        TitleHeader,
    },
    data(){
        return{
            title: 'Ads BANK',
        }
    }
}
</script>

<style lang="scss" scoped>
.content{
    width: 100%;
    height: calc(100vh - 60px);
    background: #000;
}
</style>