<template>
    <div class="mt-3">
        <table class="w-100">
            <thead>
                <tr class="text-center text-gray-600 fw-500 small">
                    <th class="py-2">순번</th>
                    <th class="py-2">마감일</th>
                    <th class="py-2">설문명</th>
                    <th class="py-2">리워드</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in list" :key="index" class="text-center hover-bg-gray-100 rounded" @click="item.check = !item.check">
                    <td class="py-2">
                        {{index+1}}
                    </td>
                    <td class="py-2">{{item.date}}</td>
                    <td class="py-2">{{item.name}}</td>
                    <td class="py-2"><b>{{item.reward.toLocaleString()}}</b>p</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    data(){
        return{
            list: [
                {
                    date: '23.6.05',
                    name: '직장인 선호도',
                    reward: 2000,
                    check: false,
                },
                {
                    date: '23.6.05',
                    name: '직장인 선호도',
                    reward: 2000,
                    check: false,
                },
                {
                    date: '23.6.05',
                    name: '직장인 선호도',
                    reward: 2000,
                    check: false,
                },
            ]
        }
    }

}
</script>

<style>

</style>