<template>
    <div class="page">
        <TitleHeader :title="title" />

        <div class="px-3">
            <div class="btn bg-sub text-primary w-100">
                Lorem ipsum
            </div>
            <CateBtns :list="cate" />
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
import CateBtns from '@/components/common/CateBtns.vue'

export default {
    components:{
        TitleHeader,
        CateBtns
    },
    data(){
        return{
            title: '경품 BANK',
            cate: [
                {
                    title: '당첨<br>내역',
                    link: '/gift-bank/won-gift'
                },
                {
                    title: '소진<br>내역',
                    link: '/gift-bank/received-gift'
                },
            ]
        }
    }

}
</script>
